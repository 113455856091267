<template>
<svg
	width="100%"
	height="100%"
	viewBox="0 0 512 512"
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlnsXlink="http://www.w3.org/1999/xlink"
	xmlSpace="preserve"
	:style="{
	  fillRule: 'evenodd',
	  clipRule: 'evenodd',
	  strokeLinejoin: 'round',
	  strokeMiterlimit: 2,
	}"
  >
	<g transform="matrix(0.1,0,0,-0.1,0,512)">
	  <path
		d="M2340,5083C2257,5076 2048,5040 1951,5015C1789,4974 1622,4912 1465,4835C1187,4699 1014,4575 790,4350C565,4125 440,3952 305,3675C190,3440 113,3193 73,2930C48,2766 48,2382 74,2215C143,1761 332,1333 619,983C1016,497 1573,182 2210,83C2296,70 2385,65 2565,65C2809,65 2892,73 3080,112C4022,309 4782,1041 5009,1972C5063,2195 5075,2300 5075,2575C5075,2850 5063,2955 5009,3178C4959,3384 4880,3587 4774,3780C4379,4505 3634,5000 2816,5079C2714,5089 2444,5091 2340,5083ZM1797,3740C1869,3696 1864,3755 1870,2875L1875,2085L1899,2015C1956,1840 2077,1698 2230,1623C2367,1556 2414,1550 2841,1550C3251,1550 3258,1549 3303,1486C3322,1460 3325,1442 3325,1368C3325,1271 3312,1242 3249,1205C3217,1186 3197,1185 2815,1185C2388,1185 2339,1190 2185,1242C1916,1333 1675,1568 1574,1839C1509,2012 1510,1997 1510,2872C1510,3632 1511,3666 1529,3694C1563,3746 1597,3760 1685,3760C1747,3760 1772,3755 1797,3740ZM3798,3720C3851,3688 3870,3646 3870,3562C3870,3484 3862,3461 3822,3418C3793,3386 3783,3384 3630,3370C3494,3358 3395,3333 3284,3281C3084,3187 2930,3043 2830,2857C2733,2678 2710,2579 2701,2305C2694,2077 2690,2066 2613,2029C2576,2011 2557,2008 2498,2012C2342,2024 2311,2109 2339,2450C2357,2668 2392,2802 2476,2974C2665,3356 3028,3637 3435,3714C3479,3722 3524,3731 3535,3733C3546,3736 3602,3738 3660,3739C3748,3740 3770,3737 3798,3720Z"
		:style="{fillRule: 'nonzero'}"
	  />
	</g>
  </svg>
</template>
